import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import MajorNavbar from "./components/MajorNavbar/MajorNavbar";
import TimeTrial from "./pages/TimeTrial/TimeTrial";
import Policy from "./pages/Policy/Policy";

const BigRoutes = ({ className }) => {
  return (
    <Router>
      <div className={className}>
        <MajorNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/time-trial" element={<TimeTrial />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </div>
    </Router>
  );
};

export default BigRoutes;
