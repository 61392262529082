import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRandom,
  faMicrophone,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

import "./TimeTrialBottomFunctions.scss";
import { randomizeArray, toggleModal } from "./TimeTrialBottomFunctions.func";
import TimeTrialModal from "../TimeTrialModal/TimeTrialModal";

const TimeTrialBottomFunctions = ({ array, setArray }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="time-trial-bottom-functions-container">
      <button
        className="time-trial-bottom-functions-button"
        aria-label="Randomize Words"
        onClick={() => {
          randomizeArray({ setArray: (arr) => setArray(arr), array });
        }}
      >
        <FontAwesomeIcon icon={faRandom} />
      </button>
      <button
        className="time-trial-bottom-functions-button"
        aria-label="Hold To Speak"
      >
        <FontAwesomeIcon icon={faMicrophone} />
      </button>
      <button
        className="time-trial-bottom-functions-button"
        aria-label="Select Lesson"
        onClick={() =>
          toggleModal({
            isModalOpen,
            setIsModalOpen: (bool) => setIsModalOpen(bool),
          })
        }
      >
        <FontAwesomeIcon icon={faCog} />
      </button>
      {isModalOpen && <TimeTrialModal />}
    </div>
  );
};

export default TimeTrialBottomFunctions;
