import React from "react";

import "./TimeTrialMainCard.scss";

const TimeTrialMainCard = ({ word = "Great Job!", description = "Complete" }) => {
  return (
    <div className="time-trial-main-card-container">
      <h1>{word}</h1>
      {/* <p>{description}</p> */}
    </div>
  );
};

export default TimeTrialMainCard;
