export const timerIntervalHelper = (setTime, isRunning) => {
  let intervalId;

  if (isRunning) {
    intervalId = setInterval(() => {
      setTime((prevTime) => prevTime + 10);
    }, 10);
  }

  return () => {
    clearInterval(intervalId);
  };
};

export const startStopwatch = (setIsRunning) => {
  setIsRunning(true);
};

export const stopStopwatch = (setIsRunning) => {
  setIsRunning(false);
};

export const resetStopwatch = (setTime) => {
  setTime(0);
};
