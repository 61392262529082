import React from "react";

import "./TimeTrialCorrectIncorrectCard.scss";

const TimeTrialCorrectIncorrectCard = ({
  correctCount = 0,
  incorrectCount = 0,
}) => {
  return (
    <div className="time-trial-correct-incorrect-card-container">
      <div className="time-trial-correct-incorrect-card">
        <h1>Correct</h1>
        <p>{correctCount}</p>
      </div>
      <div className="time-trial-correct-incorrect-card">
        <h1>Incorrect</h1>
        <p>{incorrectCount}</p>
      </div>
    </div>
  );
};

export default TimeTrialCorrectIncorrectCard;
