import React from "react";

import "./Home.scss";

const Home = () => {
  return (
    <div className="home-container">
      <h1>Welcome to the Home Page</h1>
      <p>This is the content of the home page.</p>
      <a href="/time-trial">Time Trial</a>
    </div>
  );
};

export default Home;
