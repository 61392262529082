import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import "./MajorNavbar.scss";

const MajorNavbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 480);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSmallScreen) {
      setShowSidebar(false);
    }
  }, [isSmallScreen]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <nav className="major-navbar">
      <div className="logo">
        <a href="/">EarlyReaderAI</a>
      </div>
      <div className="menu">
        <p>Log In</p>
        <p>Sign Up</p>
      </div>
      {isSmallScreen && <div className={`blur ${showSidebar ? "show" : ""}`} />}
      {isSmallScreen && (
        <div
          onClick={toggleSidebar}
          className={`sidebar ${showSidebar ? "show" : ""}`}
        >
          <div className="menu">
            <p>Log In</p>
            <p>Sign Up</p>
          </div>
        </div>
      )}
      <button
        className="sidebar-toggle"
        aria-label="Toggle Sidebar"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} className="icon" />
      </button>
    </nav>
  );
};

export default MajorNavbar;
