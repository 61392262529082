import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";

import "./TimeTrialRestart.scss";

const TimeTrialRestart = ({
  origArr,
  arrayName,
  setArray,
  setArrayName,
  setTime,
  setIncorrectCount,
  setCorrectCount,
}) => {
  return (
    <div className="time-trial-restart-container">
      <button
        className="time-trial-restart-button"
        onClick={() => {
          setArray(origArr);
          setArrayName(arrayName);
          setTime(0);
          setCorrectCount(0);
          setIncorrectCount(0);
        }}
      >
        <FontAwesomeIcon icon={faArrowRotateLeft} />
      </button>
    </div>
  );
};

export default TimeTrialRestart;
