import BigRoutes from "./routes";

import "./reset.scss";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <p className="not-supported">
        This device is not supported. A screen width of at least 300 pixels is
        required. If you somehow have a device that is this small, contact me
        and I will attempt to make my site support the thing.
      </p>
      <BigRoutes className="big-routes" />
    </div>
  );
}

export default App;
