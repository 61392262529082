import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  fa0,
  fa1,
  fa2,
  fa3,
  fa4,
  fa5,
  fa6,
  fa7,
  fa8,
  fa9,
} from "@fortawesome/free-solid-svg-icons";

import "./TimeTrialModal.scss";

const TimeTrialModal = () => {
  const [lsOpen, setLSOpen] = useState(false);
  const [lnOpen, setLNOpen] = useState(false);
  const [fwOpen, setFWOpen] = useState(false);

  return (
    <div className="time-trial-modal" aria-modal="true">
      <div
        className={`modal-card ${lsOpen ? "modal-card-open" : ""}`}
        onClick={() => setLSOpen(!lsOpen)}
      >
        <div className="modal-title-container">
          <h1 className="modal-title">Letter Sounds</h1>
          <FontAwesomeIcon icon={lsOpen ? faChevronUp : faChevronDown} />
        </div>
        {lsOpen && (
          <div className="modal-content-container">
            <a href="/time-trial?lesson_name=wip-not-finished">26 Lower</a>
            <a href="/time-trial?lesson_name=wip-not-finished">26 Upper</a>
          </div>
        )}
      </div>

      <div
        className={`modal-card ${lnOpen ? "modal-card-open" : ""}`}
        onClick={() => setLNOpen(!lnOpen)}
      >
        <div className="modal-title-container">
          <h1 className="modal-title">Letter Names</h1>
          <FontAwesomeIcon icon={lnOpen ? faChevronUp : faChevronDown} />
        </div>
        {lnOpen && (
          <div className="modal-content-container">
            <a href="/time-trial?lesson_name=wip-not-finished">26 Lower</a>
            <a href="/time-trial?lesson_name=wip-not-finished">26 Upper</a>
          </div>
        )}
      </div>

      <div
        className={`modal-card ${fwOpen ? "modal-card-open" : ""}`}
        onClick={() => setFWOpen(!fwOpen)}
      >
        <div className="modal-title-container">
          <h1 className="modal-title">Fry Words</h1>
          <FontAwesomeIcon icon={fwOpen ? faChevronUp : faChevronDown} />
        </div>
        {fwOpen && (
          <div className="modal-content-container">
            <a href="/time-trial?lesson_name=fry1">
              <FontAwesomeIcon icon={fa1} />
            </a>
            <a href="/time-trial?lesson_name=fry2">
              <FontAwesomeIcon icon={fa2} />
            </a>
            <a href="/time-trial?lesson_name=fry3">
              <FontAwesomeIcon icon={fa3} />
            </a>
            <a href="/time-trial?lesson_name=fry4">
              <FontAwesomeIcon icon={fa4} />
            </a>
            <a href="/time-trial?lesson_name=fry5">
              <FontAwesomeIcon icon={fa5} />
            </a>
            <a href="/time-trial?lesson_name=fry6">
              <FontAwesomeIcon icon={fa6} />
            </a>
            <a href="/time-trial?lesson_name=fry7">
              <FontAwesomeIcon icon={fa7} />
            </a>
            <a href="/time-trial?lesson_name=fry8">
              <FontAwesomeIcon icon={fa8} />
            </a>
            <a href="/time-trial?lesson_name=fry9">
              <FontAwesomeIcon icon={fa9} />
            </a>
            <a href="/time-trial?lesson_name=fry10">
              <FontAwesomeIcon icon={fa1} />
              <FontAwesomeIcon icon={fa0} />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeTrialModal;
