import React, { useState, useEffect } from "react";
import axios from "axios";

import StopwatchTimeCard from "./../StopwatchTimeCard/StopwatchTimeCard";
import {
  timerIntervalHelper,
  startStopwatch,
  stopStopwatch,
  resetStopwatch,
} from "./TimeTrial.func";
import TimeTrialMainCard from "./TimeTrialMainCard/TimeTrialMainCard";
import TimeTrialCorrectIncorrectCard from "./TimeTrialCorrectIncorrectCard/TimeTrialCorrectIncorrectCard";
import TimeTrialBottomButtons from "./TimeTrialBottomButtons/TimeTrialBottomButtons";
import TimeTrialBottomFunctions from "./TimeTrailBottomFunctions/TimeTrialBottomFunctions";

import "./TimeTrial.scss";
import TimeTrialRestart from "./TimeTrialRestart/TimeTrialRestart";

const TimeTrial = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [array, setArray] = useState(["loading..."]);
  const [origArr, setOrigArr] = useState(["loading..."]);
  const [arrayName, setArrayName] = useState("fry1");

  useEffect(() => {
    const cleanup = timerIntervalHelper(setTime, isRunning);

    return () => {
      cleanup();
    };
  }, [isRunning]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lessonName = params.get("lesson_name");
    lessonName && setArrayName(lessonName);
    resetStopwatch(setTime);

    const fetchData = async (lessonName) => {
      try {
        const response = await axios({
          url: "https://earlyreader.ai/graphql",
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            query: `
              query {
                lessonByName(lesson_name: "${lessonName}") {
                  lesson_name
                  words
                }
              }
            `,
          },
        });

        if (response.data.data && response.data.data.lessonByName) {
          setArray(response.data.data.lessonByName.words);
          setOrigArr(response.data.data.lessonByName.words);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData(lessonName || arrayName);
  }, [arrayName]);

  return (
    <div className="time-trial-container">
      <StopwatchTimeCard time={time} />
      <TimeTrialCorrectIncorrectCard
        correctCount={correctCount}
        incorrectCount={incorrectCount}
      />
      <TimeTrialMainCard word={array[0]} description={arrayName} />
      {array.length > 0 ? (
        <TimeTrialBottomButtons
          array={array}
          incorrectCount={incorrectCount}
          correctCount={correctCount}
          setIncorrectCount={(num) => setIncorrectCount(num)}
          setCorrectCount={(num) => setCorrectCount(num)}
          setArray={(arr) => setArray(arr)}
          startStopwatch={() => startStopwatch(setIsRunning)}
          stopStopwatch={() => stopStopwatch(setIsRunning)}
        />
      ) : (
        <TimeTrialRestart
          origArr={origArr}
          arrayName={arrayName}
          setArray={(arr) => setArray(arr)}
          setArrayName={(name) => setArrayName(name)}
          setTime={(time) => setTime(time)}
          setCorrectCount={(num) => setCorrectCount(num)}
          setIncorrectCount={(num) => setIncorrectCount(num)}
        />
      )}
      <TimeTrialBottomFunctions
        setArray={(arr) => setArray(arr)}
        array={array}
      />
    </div>
  );
};

export default TimeTrial;
