export const handleWrongResponse = ({
  array,
  setArray,
  startStopwatch,
  setIncorrectCount,
  incorrectCount,
}) => {
  if (array.length === 0) return;
  const currentItem = array[0];
  const newArray = array.slice(1);
  newArray.push(currentItem);
  setArray(newArray);
  incorrectCount === 0 && startStopwatch();
  setIncorrectCount(incorrectCount + 1);
};

export const handleCorrectResponse = ({
  array,
  setArray,
  stopStopwatch,
  startStopwatch,
  correctCount,
  setCorrectCount,
}) => {
  const newArray = array.slice(1);
  newArray.length === 0 && stopStopwatch();
  setArray(newArray);
  correctCount === 0 && startStopwatch();
  setCorrectCount(correctCount + 1);
};
