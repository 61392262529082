import React from "react";

import { formatTime } from "./StopwatchTimeCard.func";

import "./StopwatchTimeCard.scss";

const StopwatchTimeCard = ({ time }) => {
  return (
    <div className="stopwatch-time-card">
      {formatTime(time) === "invalid" ? (
        <h1 style={{ fontSize: "24px" }}>Are you on a quest?</h1>
      ) : (
        <h1>{formatTime(time)}</h1>
      )}
    </div>
  );
};

export default StopwatchTimeCard;
