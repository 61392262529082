import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

import {
  handleCorrectResponse,
  handleWrongResponse,
} from "./TimeTrialBottomButtons.func";

import "./TimeTrialBottomButtons.scss";

const TimeTrialBottomButtons = ({
  array,
  setArray,
  incorrectCount,
  correctCount,
  setIncorrectCount,
  setCorrectCount,
  startStopwatch,
  stopStopwatch,
}) => {
  return (
    <div className="time-bottom-buttons-container">
      <button
        className="time-trial-bottom-button"
        aria-label="Correct"
        onClick={() =>
          handleCorrectResponse({
            array,
            setArray: (arr) => setArray(arr),
            stopStopwatch,
            startStopwatch,
            correctCount,
            setCorrectCount: (num) => setCorrectCount(num),
          })
        }
      >
        <FontAwesomeIcon icon={faCheck} />
      </button>
      <button
        className="time-trial-bottom-button"
        aria-label="Incorrect"
        onClick={() =>
          handleWrongResponse({
            array,
            setArray: (arr) => setArray(arr),
            startStopwatch,
            setIncorrectCount: (num) => setIncorrectCount(num),
            incorrectCount,
          })
        }
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  );
};

export default TimeTrialBottomButtons;
